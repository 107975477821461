import React, { createContext, useContext, useMemo, useState } from 'react'

export enum EntryPage {
  Cart = 'cart',
  Collection = 'collection',
  Product = 'product',
  ProductBanner = 'product-banner'
}

export interface NavigationEntry {
  page: EntryPage
  reference: string
}

interface NavigationContext {
  current: () => NavigationEntry
  pop: () => void // NavigationEntry
  push: (_: NavigationEntry) => void
}

interface Props {
  children: React.ReactNode
}

const Context = createContext<NavigationContext>(undefined)

export const NavigationContextProvider: React.FC<Props> = props => {
  const [navigationEntries, setNavigationEntries] = useState<NavigationEntry[]>([])
  const ref = useMemo<NavigationContext>(() => ({
    current: () => navigationEntries.slice(-1).pop(),
    pop: () => setNavigationEntries(entries => entries.slice(0, entries.length - 1)),
    push: entry => setNavigationEntries(entries => [...entries, entry])
  }), [navigationEntries])
  return <Context.Provider value={ref} {...props} />
}

export function useNavigationContext() {
  return useContext(Context)
}