import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";

import { classNames } from "app/util/class-names";
//@ts-ignore

import classes from "./ModalComponent.module.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
  opened?: boolean;
  onClosed?: () => void;
  onOpened?: () => void;
}

export const ModalComponent: React.FC<Props> = ({
  className,
  opened,
  onClosed,
  onOpened,
  ...props
}) => {
  const { /* isOpen, onOpen, */ onClose } = useDisclosure({
    onClose: () => onClosed(),
    onOpen: () => onOpened(),
  });
  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      isCentered={true}
      scrollBehavior="inside"
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent
        className={classNames(className, classes.modal, classes.content)}
        {...props}
      />
    </Modal>
  );
};
