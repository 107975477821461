import { v4 as uuidv4 } from "uuid";
const tourId = process.env.REACT_APP_ID_TOUR;
interface AnalyticsObject {
  traffic?: any;
  ip: string;
  tourId: string;
  clientId: string;
  session: string;
  action: string;

  productId?: string;
  idTag?: string;
  modal_time?: number;
  floor?: number;
  count?: number;
  localization?: any;
}

let tour = "0";
let ip = "0";
//date now in timeStapm
let startTime = new Date().getTime();
let startTimeModalOpen = 0;
let startTimeAddProduct = 0;
let startTimeCheckout = 0;

const checkoutSession = uuidv4();

export function getSessionUser() {
  const sessionUser = localStorage.getItem("sessionUser");
  if (sessionUser) {
    return sessionUser;
  }
  const uuid = uuidv4();
  localStorage.setItem("sessionUser", uuid);
  return uuid;
}
async function getIp() {
  if (ip === "0") {
    const response = await fetch("https://api64.ipify.org/");
    ip = await response.text();
  }
  return ip;
}

function getDeviceType(width) {
  let device = "";
  if (width < 768) {
    device = "mobile";
  }
  if (width >= 768 && width < 992) {
    device = "tablet";
  }
  if (width >= 992) {
    device = "desktop";
  }

  return device;
}

const getTraffic = async (width) => {
  return {
    date: new Date().toISOString().split("T")[0],
    hour: new Date().toISOString().split("T")[1].split(".")[0],
    origin: "Westwing",
    device_type: getDeviceType(width),
    //@ts-ignore
    operational_system: navigator?.userAgentData?.platform || "unknown",
    campaign: "Joaninha",
  };
};

export const entryTour = async ({ width }) => {
  const sessionUser = getSessionUser();

  const body: AnalyticsObject = {
    traffic: await getTraffic(width),
    ip: await getIp(),
    tourId,
    clientId: "xwVvpAiZrLq",
    session: sessionUser,
    action: "entryTour",
  };
  postData(body);
};

export const clickTag = async (
  { width },
  idTag: string,
  idProduct: string,
  nameProduct: string
) => {
  const sessionUser = getSessionUser();
  const timeNow = new Date().getTime();
  startTimeAddProduct = timeNow;
  startTimeModalOpen = timeNow;
  const body: AnalyticsObject = {
    traffic: await getTraffic(width),
    ip: await getIp(),
    tourId,
    clientId: "xwVvpAiZrLq",
    session: sessionUser,
    action: "onClickTag",
    idTag,
    productId: idProduct,
  };
  postData(body);
};

export const clickBuy = async (
  { width },
  idProduct: string,
  nameProduct: string
) => {
  const sessionUser = getSessionUser();
  startTimeCheckout = new Date().getTime();

  const body: AnalyticsObject = {
    traffic: await getTraffic(width),
    ip: await getIp(),
    tourId,
    clientId: "xwVvpAiZrLq",
    session: sessionUser,
    action: "onClickBuy",
    productId: idProduct,
    count: 1,
    modal_time: new Date().getTime() - startTimeAddProduct,
  };
  postData(body);
};

export const goToCheckout = async ({ width }, items: any) => {
  const sessionUser = getSessionUser();
  const body = {
    traffic: await getTraffic(width),
    ip: await getIp(),
    tourId,
    clientId: "xwVvpAiZrLq",
    session: sessionUser,
    action: "onClickCheckout",
    count: 1,
  };

  postData(body);
  return;
};

export const closeModal = async (
  { width },
  idProduct,
  nameProduct,
  modalType
) => {
  const sessionUser = getSessionUser();
  const body = {
    traffic: await getTraffic(width),
    ip: await getIp(),
    tourId,
    clientId: "xwVvpAiZrLq",
    session: sessionUser,
    action: "onCloseModal",
    productId: idProduct,
    modal_time: (new Date().getTime() - startTimeModalOpen) / 1000,
  };
  postData(body);
};

export const removeCart = async (idProduct: string, nameProduct: string) => {
  const sessionUser = getSessionUser();
  const width = window.innerWidth;
  const body: AnalyticsObject = {
    traffic: await getTraffic(width),
    ip: await getIp(),
    tourId,
    clientId: "xwVvpAiZrLq",
    session: sessionUser,
    action: "onRemoveCart",
    productId: idProduct,
    modal_time: (new Date().getTime() - startTimeAddProduct) / 1000,
  };
  postData(body);
};
export const onMove = async ({ width }, x, y, z) => {
  const sessionUser = getSessionUser();
  const body: AnalyticsObject = {
    traffic: await getTraffic(width),
    ip: await getIp(),
    tourId,
    clientId: "xwVvpAiZrLq",
    session: sessionUser,
    action: "onMove",
    localization: {
      x: x.toString(),
      y: y.toString(),
      z: z.toString(),
    },
  };
  postData(body);
};

async function postData(body) {
  try {
    //disable analytics
    // await fetch(process.env.REACT_APP_ANALYTICS_URL, {
    //   method: "POST",
    //   headers: { "content-type": "application/json" },
    //   body: JSON.stringify(body),
    // });
  } catch (error) {
    console.log(error);
  }
}
