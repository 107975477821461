import React, { useEffect } from "react";
import { Icon, ModalBody } from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";

import { classNames } from "app/util/class-names";

import { ModalComponent } from "../components/ModalComponent";
//@ts-ignore

import classes from "./InfoModal.module.scss";

interface Props {
  banner?: boolean;
  fromCollection?: boolean;
  setFromCollection?: (_: (_: boolean) => boolean) => void;
  opened?: boolean;
  onClosed?: (product?: any) => void;
  product?: any;
}

export const InfoModal: React.FC<Props> = ({ onClosed, ...props }) => {
  useEffect(() => {}, []);
  return (
    <ModalComponent
      className={classNames(classes.modal, classes.width)}
      onClosed={onClosed}
      {...props}
    >
      <ModalBody className={classNames(classes.body)}>
        <h2>Como funciona o 3D-commerce?</h2>
        <button className={classes.close} type="button" onClick={onClosed}>
          <Icon as={FaTimes} />
        </button>
        <div className={classNames(classes.container)}>
          <div className={classNames(classes.desktop)}>
            <img src="/img/step-desktop.png" alt="3d-commerce" />
          </div>
          <div className={classNames(classes.mobile)}>
            <img src="/img/step-mobile.png" alt="3d-commerce" />
          </div>
        </div>
      </ModalBody>
    </ModalComponent>
  );
};
