import {
  YoutubeModal,
  ImagesModal,
  PdfModal,
  Productmodal,
  ProductsModal,
} from "@3blocks-components/3blocks-components";
import React, { useEffect, useState } from "react";
import "./style.css";

export const Modals3Blocks = ({
  tag,
  onClose,
  idTour,
  urlProduct,
  objectVisual,
  handleBuy,
  handleClose,
}: any) => {
  const [products, setProducts] = useState<any>([]);

  useEffect(() => {
    if (tag.type_v2 === "single_product") {
      fetchProduct();
    }
    if (tag.type_v2 === "multi_product") {
      fetchProducts();
    }
  }, [tag.type_v2]);

  function fetchProduct() {
    fetch(`${urlProduct}/${tag?.settingsType?.description}`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      });
  }

  function fetchProducts() {
    const urls = tag?.settingsType?.products?.map(
      (product: any) => `${urlProduct}/${product}`
    );

    Promise.all(urls.map((url: any) => fetch(url))).then((responses) => {
      // Get a JSON object from each of the responses
      Promise.all(
        responses.map((response) => {
          return response.json();
        })
      ).then((data) => {
        setProducts(data);
      });
    });
  }
  return (
    <div>
      {tag.type_v2 === "video" && (
        <YoutubeModal
          isOpen={true}
          onClose={onClose}
          youtubeUrl={tag?.settingsType?.url_video}
          title={tag?.settingsType?.title_video}
          headless={tag?.settingsType?.headless_video}
          borderRadius={objectVisual.visualType}
        />
      )}

      {tag.type_v2 === "image" && (
        <ImagesModal
          isOpen={true}
          onClose={onClose}
          title={tag?.settingsType?.title_image}
          type={tag?.settingsType?.style}
          buttonName={
            tag?.settingsType?.button && tag?.settingsType?.title_image_button
          }
          buttonLink={
            tag?.settingsType?.button && tag?.settingsType?.url_image_button
          }
          description={
            tag?.settingsType?.style === "normal"
              ? [tag?.settingsType?.description_image]
              : Object.values(tag?.settingsType?.individualDescription)
          }
          images={
            (tag.images.data &&
              tag.images.data.map((image: any) => image?.attributes.url)) ||
            []
          }
          borderRadius={objectVisual.visualType}
        />
      )}
      {tag.type_v2 === "pdf" && (
        <PdfModal
          isOpen={true}
          onClose={onClose}
          title={tag?.settingsType?.title}
          description={tag?.settingsType?.description}
          pdf={tag?.images?.data[0]?.attributes?.url}
          type="pdf"
          borderRadius={objectVisual.visualType}
        />
      )}
      {tag.type_v2 === "single_product" && (
        <Productmodal
          modal={true}
          open={true}
          // @ts-ignore
          similar={null}
          handleClickSimilar={() => {}}
          handleBuy={handleBuy}
          handleClose={handleClose}
          product={products}
          onlyDevMockType="default"
          visual={objectVisual}
          textButtonBuy="Ver mais sobre o produto"
          buttonSeeMoreSpecification={false}
          alwaysAvailable={true}
          priceFontSize={1.5}
          showCountPrice={false}
        />
      )}
      {tag.type_v2 === "multi_product" && (
        <ProductsModal
          title=""
          handleClick={() => {}}
          open={true}
          handleClose={onClose}
          products={products}
          //@ts-ignore
          visual={objectVisual}
        />
      )}
    </div>
  );
};
