import React, { useEffect, useRef, useState } from "react";
import { LinearFilter, RGBAFormat } from "three";

// TODO

import { CartContextProvider } from "app/contexts/CartContext";
import { NavigationContextProvider } from "app/contexts/NavigationContext";

import { CollectionModal } from "./modal/collection/CollectionModal";
import { InfoModal } from "./modal/info/InfoModal";
import { ProductModal } from "./modal/product/ProductModal";
import { Navbar } from "./navbar/Navbar";
import { ModalComponent } from "./modal/components/ModalComponent";
import useWebSocket from "react-use-websocket";
//@ts-ignore
import classes from "./Container.module.scss";
import {
  clickBuy,
  clickTag,
  closeModal,
  goToCheckout,
  entryTour,
  onMove,
  getSessionUser,
} from "app/util/analytics";
import Share from "./share/share";
import { FloorNav } from "./modal/components/FloorNav";

let defaultRotation = {
  x: 0.01,
  y: 0.889,
  z: -0.01,
};

let defaultPosition = {
  x: 13.7,
  y: -1.7854952832368285,
  z: 4.487614055806306,
};

const showTransformButtons = false;

class ImageComponent {
  componentType: string;
  context: any;
  src: string;
  scale: any;

  constructor(id: string, scale: any, url: any) {
    this.componentType = id;
    this.src = url;
    this.scale = scale;
  }

  async onInit() {
    const pivotNode = new this.context.three.Group();

    const audioState = document.createElement("img");
    audioState.src = this.src;

    const textureAudioState = new this.context.three.CanvasTexture(audioState);

    const geometry = new this.context.three.ShapeGeometry(
      new this.context.three.Shape([
        new this.context.three.Vector2(0, 0), // bottom left
        new this.context.three.Vector2(0.6819887326, 0), // bottom right
        new this.context.three.Vector2(0.6819887326, 1.2109079634), // top right
        new this.context.three.Vector2(-0.001, 1.2109079634), // top left
      ])
    );

    geometry.computeVertexNormals();

    const meshAudioState = new this.context.three.Mesh(
      new this.context.three.PlaneGeometry(1, 1),
      new this.context.three.MeshBasicMaterial({
        transparent: true,
        map: textureAudioState,
        opacity: 1,
      })
    );
    // Scale
    meshAudioState.scale.set(this.scale.x, this.scale.y, this.scale.z);

    // Position
    meshAudioState.position.set(0, 0, 0);

    // Rotation
    meshAudioState.rotation.set(0, 0, 0);

    meshAudioState.updateMatrixWorld();
    pivotNode.add(meshAudioState);

    //@ts-ignore
    this.outputs.objectRoot = pivotNode;
    //@ts-ignore
    this.outputs.collider = pivotNode;
  }

  onDestroy() {
    console.log("VideoComponent::onDestroy() called");
  }

  bind(prop: string, src: any, srcProp: string): void {
    // console.error(arguments.callee.name, 'Method not implemented.')
  }
  bindEvent(eventType: string, src: any, srcEventType: string): void {
    // console.error(arguments.callee.name, 'Method not implemented.')
  }
  notify(eventType: string, eventData?: unknown): void {
    //
  }
  spyOnEvent(spy: any) {
    throw new Error("SpyOnEvent Method not implemented.");
  }
}

class VideoComponent {
  componentType: string = "brd.videoComponent";
  context: any;
  events = { "INTERACTION.CLICK": true };
  video?: HTMLVideoElement;
  mesh?: any;

  async onInit() {
    const pivotNode = new this.context.three.Group();

    this.video = this.video || document.createElement("video");
    this.video.crossOrigin = "anonymous";
    this.video.playsInline = true;
    this.video.autoplay = true;
    this.video.muted = true;
    this.video.loop = true;
    this.video.src = "/video/videoplayback.mp4";
    this.mesh = null;

    requestAnimationFrame(() => this.video.play());

    const background = document.createElement("img");
    background.src = "/video/background.jpg";

    const audioState = document.createElement("img");
    audioState.src = "/tips/volume-mute-fill.png";
    audioState.height = 128;
    audioState.width = 128;

    const texture = new this.context.three.VideoTexture(this.video);
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    texture.format = RGBAFormat;
    texture.repeat.set(1.51, 0.845);
    texture.offset.x = -0.01;
    texture.offset.y = -0.015;

    const textureBackground = new this.context.three.CanvasTexture(background);

    const textureAudioState = new this.context.three.CanvasTexture(audioState);

    const geometry = new this.context.three.ShapeGeometry(
      new this.context.three.Shape([
        new this.context.three.Vector2(0, 0), // bottom left
        new this.context.three.Vector2(0.6819887326, 0), // bottom right
        new this.context.three.Vector2(0.6819887326, 1.2109079634), // top right
        new this.context.three.Vector2(-0.001, 1.2109079634), // top left
      ])
    );

    geometry.computeVertexNormals();

    const mesh = new this.context.three.Mesh(
      geometry,
      new this.context.three.MeshBasicMaterial({
        transparent: false,
        map: texture,
        opacity: 1,
        polygonOffset: false,
        polygonOffsetFactor: 0,
        polygonOffsetUnits: 0,
      })
    );
    const meshBackground = new this.context.three.Mesh(
      geometry.clone(),
      new this.context.three.MeshBasicMaterial({
        transparent: false,
        map: textureBackground,
        opacity: 1,
      })
    );

    const meshAudioState = new this.context.three.Mesh(
      new this.context.three.PlaneGeometry(1, 1),
      new this.context.three.MeshBasicMaterial({
        transparent: true,
        map: textureAudioState,
        opacity: 1,
      })
    );
    // Scale
    mesh.scale.set(3.6, 1.7, 1);
    meshBackground.scale.set(3.75, 1.8, 1);
    meshAudioState.scale.set(0.25, 0.25, 1);

    // Position
    mesh.position.set(0, 0, 0);
    meshBackground.position.set(-0.05, -0.05, -0.02);
    meshAudioState.position.set(0.3, 0.3, 0.02);

    // Rotation
    mesh.rotation.set(0, 0, 0);
    meshBackground.rotation.set(0, 0, 0);
    meshAudioState.rotation.set(0, 0, 0);

    mesh.updateMatrixWorld();
    meshBackground.updateMatrixWorld();
    meshAudioState.updateMatrixWorld();
    pivotNode.add(mesh);
    pivotNode.add(meshBackground);
    pivotNode.add(meshAudioState);
    this.mesh = mesh;

    // TODO: change this
    setInterval(() => {
      if (this.isPlayingVideo()) {
        const source = this.video?.muted
          ? "/img/volume-mute-fill.png"
          : "/img/volume-up-fill.png";
        if (source !== audioState.src) {
          audioState.src = source;
          const textureLoader = new this.context.three.TextureLoader();
          textureLoader.load(source, (texture) => {
            meshAudioState.material.map = texture;
            meshAudioState.material.needsUpdate = true;
          });
        }
        // textureAudioState.image = audioState
        // textureAudioState.updateMatrix()
        // meshAudioState.material.map = textureAudioState
        // meshAudioState.updateMatrixWorld()
        // pivotNode.updateMatrixWorld()
        // pivotNode.updateWorldMatrix(true, true)
      }
    }, 500);
    // ==

    //@ts-ignore
    this.outputs.objectRoot = pivotNode;
    //@ts-ignore
    this.outputs.collider = pivotNode;
  }

  private isPlayingVideo() {
    return (
      this.video?.currentTime > 0 &&
      this.video?.readyState > 2 &&
      !this.video?.ended &&
      !this.video?.paused
    );
  }

  async onEvent(eventType: any, _eventData: unknown) {
    if (eventType === "INTERACTION.CLICK") {
      if (this.isPlayingVideo()) {
        this.video.muted = !this.video.muted;
      } else if (this.video && this.video.paused) {
        this.video.play();
        this.video.muted = false;
      }
    }
  }

  onDestroy() {
    console.log("VideoComponent::onDestroy() called");
  }

  // onInputsUpdated(previousInputs: Record<string, unknown>): void {
  //   console.error(arguments.callee.name, 'Method not implemented.')
  // }
  // onTick(tickDelta: number): void {
  //   console.error(arguments.callee.name, 'Method not implemented.')
  // }
  // onDestroy(): void {
  //   console.error(arguments.callee.name, 'Method not implemented.')
  // }
  bind(prop: string, src: any, srcProp: string): void {
    // console.error(arguments.callee.name, 'Method not implemented.')
  }
  bindEvent(eventType: string, src: any, srcEventType: string): void {
    // console.error(arguments.callee.name, 'Method not implemented.')
  }
  notify(eventType: string, eventData?: unknown): void {
    //
  }
  spyOnEvent(spy: any) {
    throw new Error("SpyOnEvent Method not implemented.");
  }
}

async function setupObject(sdk: any) {
  const [sceneObject] = await sdk.Scene.createObjects(1);
  const modelNode = sceneObject.addNode();

  modelNode.obj3D.position.set(-1.5806579722453, 0.5, 3.042);
  modelNode.obj3D.rotation.set(
    -3.141592653589793,
    -0.01570796326794831,
    -3.141592653589793
  );
  const gltfComponent = modelNode.addComponent("mp.gltfLoader", {
    url: "https://cdn.jsdelivr.net/gh/mrdoob/three.js@dev/examples/models/gltf/Parrot.glb",
    localScale: {
      x: 0.03,
      y: 0.03,
      z: 0.03,
    },
  });
  sceneObject.addInputPath(gltfComponent, "url", "gltfUrl");
  const lightsNode = sceneObject.addNode();
  lightsNode.addComponent("mp.directionalLight", {
    color: { r: 0.7, g: 0.7, b: 0.7 },
  });
  lightsNode.addComponent("mp.ambientLight", {
    intensity: 0.5,
    color: { r: 1.0, g: 1.0, b: 1.0 },
  });

  const node = sceneObject.addNode();
  const myControl = node.addComponent("mp.transformControls");
  node.start();

  // myControl.inputs.visible = true;

  // Attach the model to the transform control
  myControl.inputs.selection = modelNode;

  // set 'translate' mode to position the selection.
  myControl.inputs.mode = "translate";

  sceneObject.start();

  const tick = () => {
    requestAnimationFrame(tick);
    modelNode.obj3D.rotation.y += 0.02;
    modelNode.obj3D.rotation.x += 0.02;
    modelNode.obj3D.rotation.z += 0.02;
  };
  tick();
}

export const Container = ({
  propsTags,
  integration,
  visual,
  blocks,
  // objects,
}) => {
  const idTour = integration?.data[0].attributes.showcaseMatterportId;
  const sdkKey = integration?.data[0].attributes.apyKey;
  const urlCheckout = integration?.data[0].attributes.urlCheckout;
  const iconTags = visual?.attributes.productTagIcon.data;
  const integrationURL = integration?.data[0].attributes.integrationURL;

  // const counter = useRef(0)
  const ref = useRef<HTMLIFrameElement>(null);
  const [fromCollection, setFromCollection] = useState(false);
  const [isCollection, setIsCollection] = useState(false);
  const [isGuide, setIsGuide] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [isProductBanner, setIsProductBanner] = useState(false);
  const [product, setProduct] = useState<any>();
  const [tag, setTag] = useState<any>();
  const [tagAll, setTagAll] = useState<any>();
  const [sdk, setSdk] = useState(null);
  const [currentFloor, setCurrentFloor] = useState(0);

  const { sendJsonMessage } = useWebSocket(
    "wss://o5zg2xrf93.execute-api.us-east-1.amazonaws.com/dev",
    {
      onOpen: () => console.log(`Connected to App WS`),
      onMessage: () => {},
      onError: (event) => {
        console.error(event);
      },
      shouldReconnect: (closeEvent) => true,
      reconnectInterval: 3000,
    }
  );

  async function setupImage(
    scene: any,
    scale: any,
    position: any,
    rotation: any,
    url: string,
    showTransformButtons: boolean
  ) {
    const randomId = Math.random().toString(36).substring(7);
    const name = "brd.videoComponent" + randomId;
    scene.register(name, () => new ImageComponent(randomId, scale, url));
    const [sceneObject] = await scene.createObjects(1);
    const modelNode = sceneObject.addNode();
    modelNode.addComponent(name);

    const node = sceneObject.addNode();
    const myControl = node.addComponent("mp.transformControls");

    modelNode.obj3D.position.x = position.x;
    modelNode.obj3D.position.y = position.y;
    modelNode.obj3D.position.z = position.z;

    modelNode.obj3D.rotation.x = rotation.x;
    modelNode.obj3D.rotation.y = rotation.y;
    modelNode.obj3D.rotation.z = rotation.z;

    sceneObject.start();

    //set default rotation same as model
    defaultRotation.x = rotation.x;
    defaultRotation.y = rotation.y;
    defaultRotation.z = rotation.z;

    const tick = () => {
      requestAnimationFrame(tick);
      modelNode.obj3D.rotation.y = defaultRotation.y;
      modelNode.obj3D.rotation.x = defaultRotation.x;
      modelNode.obj3D.rotation.z = defaultRotation.z;

      console.log(
        "rotations: ",
        defaultRotation.x,
        defaultRotation.y,
        defaultRotation.z
      );

      console.log(
        "positions: ",
        modelNode.obj3D.position.x,
        modelNode.obj3D.position.y,
        modelNode.obj3D.position.z
      );
    };

    // set 'translate' mode to position the selection.
    if (showTransformButtons) {
      node.start();
      myControl.inputs.selection = modelNode;

      myControl.inputs.mode = "translate";

      tick();
    }
  }

  async function setupVideo(scene: any) {
    scene.register("brd.videoComponent", () => new VideoComponent());
    const [sceneObject] = await scene.createObjects(1);
    const modelNode = sceneObject.addNode();
    modelNode.addComponent("brd.videoComponent");

    const node = sceneObject.addNode();
    const myControl = node.addComponent("mp.transformControls");

    modelNode.obj3D.position.x = defaultPosition.x;
    modelNode.obj3D.position.y = defaultPosition.y;
    modelNode.obj3D.position.z = defaultPosition.z;

    modelNode.obj3D.rotation.x = defaultRotation.x;
    modelNode.obj3D.rotation.y = defaultRotation.y;
    modelNode.obj3D.rotation.z = defaultRotation.z;

    sceneObject.start();

    // const tick = () => {
    //   requestAnimationFrame(tick);
    //   modelNode.obj3D.rotation.y = defaultRotation.y;
    //   modelNode.obj3D.rotation.x = defaultRotation.x;
    //   modelNode.obj3D.rotation.z = defaultRotation.z;
    // };

    // set 'translate' mode to position the selection.
    if (false) {
      node.start();
      myControl.inputs.selection = modelNode;

      myControl.inputs.mode = "translate";

      // tick();
    }
  }

  useEffect(() => {
    if (idTour) {
      const showcase = document.getElementById("showcase") as any;
      const showcaseWindow = showcase.contentWindow;
      showcase.addEventListener("load", async function () {
        entryTour(getWindowSizes());
        let mpSdk;
        try {
          mpSdk = await showcaseWindow.MP_SDK.connect(showcaseWindow);

          mpSdk.on("tag.click" as any, (tagSid) => {
            const findTag = propsTags.data.find(
              (tag) => tag.attributes.sid === tagSid
            );
            setTagAll(findTag);
            clickTag(
              getWindowSizes(),
              findTag.id.toString(),
              findTag.attributes.description,
              findTag.attributes.label
            );

            setProduct(findTag.attributes.description);
            setTag(findTag.id);
            setIsProduct(true);
          });
          mpSdk.on("sweep.enter", () => {
            mpSdk.Camera.getPose().then((pose) => {
              const { x, y, z } = pose.position;
              onMove(getWindowSizes(), x, y, z);
              mpSdk.Floor.getData().then((floors) => {
                setCurrentFloor(floors.currentFloor);
              });
            });
          });
          setSdk(mpSdk);
        } catch (e) {
          console.error(e);
          return;
        }
      });
    }
  }, [idTour]);

  useEffect(() => {
    if (sdk) {
      sendJsonMessage({ route: "session", session: getSessionUser() });
      removeTags();
      registerTags();
      addTagsBack();
      setupVideo(sdk.Scene);
      addLight(sdk);
      // setupImage(
      //   sdk.Scene,
      //   {
      //     x: 0.5,
      //     y: 1.3,
      //     z: 0.5,
      //   },
      //   {
      //     x: 9.7,
      //     y: -2.45,
      //     z: 3.81,
      //   },
      //   { x: 0.09, y: -3.17, z: -0.01 },
      //   "/tips/dica_elevador.png",
      //   false
      // );

      // setupImage(
      //   sdk.Scene,
      //   {
      //     x: 2.5,
      //     y: 2.5,
      //     z: 2.5,
      //   },
      //   {
      //     x: 14.48,
      //     y: -0.63,
      //     z: 3.52,
      //   },
      //   { x: 0.01, y: 0.889, z: -0.01 },
      //   "/tips/tv_entrada.png",
      //   false
      // );

      // setupImage(
      //   sdk.Scene,
      //   {
      //     x: 1.6,
      //     y: 1.1,
      //     z: 1,
      //   },
      //   {
      //     x: 15.23,
      //     y: -1.66,
      //     z: -3.54,
      //   },
      //   { x: 0.0, y: 0, z: -0.01 },
      //   "/tips/quadro_laguna.png",
      //   false
      // );

      // setupImage(
      //   sdk.Scene,
      //   {
      //     x: 0.9,
      //     y: 0.5,
      //     z: 0.5,
      //   },
      //   {
      //     x: 12.81,
      //     y: -2.14,
      //     z: 1.6,
      //   },
      //   { x: -0.019, y: -1.16, z: -0.01 },
      //   "/tips/tv_coluna.png",
      //   false
      // );

      // setupImage(
      //   sdk.Scene,
      //   {
      //     x: 1.5,
      //     y: 1.5,
      //     z: 1,
      //   },
      //   {
      //     x: -1.24,
      //     y: -1.13,
      //     z: 1.27,
      //   },
      //   { x: -0.019, y: -4.72, z: 0.02 },
      //   "/tips/logo_externo.png",
      //   false
      // );

      // setupImage(
      //   sdk.Scene,
      //   {
      //     x: 2.4,
      //     y: 0.95,
      //     z: 1,
      //   },
      //   {
      //     x: 6.36,
      //     y: -2.64,
      //     z: -7.74,
      //   },
      //   { x: -0.019, y: -4.71, z: 0.02 },
      //   "/tips/balcao.png",
      //   false
      // );
      // setupImage(
      //   sdk.Scene,
      //   {
      //     x: 0.85,
      //     y: 0.78,
      //     z: 0.8,
      //   },
      //   {
      //     x: 22.03,
      //     y: 3.75,
      //     z: -2.4213,
      //   },
      //   { x: 0.0, y: -1.61, z: 0.02 },
      //   "/tips/geladeira.png",
      //   false
      // );

      // setupImage(
      //   sdk.Scene,
      //   {
      //     x: 0.95,
      //     y: 1,
      //     z: 1,
      //   },
      //   {
      //     x: 14.13,
      //     y: 3.81,
      //     z: 0.79,
      //   },
      //   { x: -0.019, y: -4.69, z: 0.02 },
      //   "/tips/caixa_2andar.png",
      //   false
      // );

      // setupImage(
      //   sdk.Scene,
      //   {
      //     x: 0.9,
      //     y: 0.6,
      //     z: 1,
      //   },
      //   {
      //     x: 7.0194,
      //     y: 4.78,
      //     z: 5.25,
      //   },
      //   { x: 0.0, y: -1.53, z: 0.02 },
      //   "/tips/quadroluz.png",
      //   false
      // );

      // setupImage(
      //   sdk.Scene,
      //   {
      //     x: 1.6,
      //     y: 1.1,
      //     z: 1,
      //   },
      //   {
      //     x: 8.541,
      //     y: 4.845,
      //     z: -12.99,
      //   },
      //   { x: 0.001, y: -6.26, z: 0.0 },
      //   "/tips/sofa_azul.png",
      //   false
      // );
      const interval = setInterval(() => {
        sendPingMessage();
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [sdk]);

  function sendPingMessage() {
    const message = { route: "ping", body: "ping" };
    sendJsonMessage(message);
  }

  useEffect(() => {
    if (!sdk || !blocks) return;
    blocks.forEach((block) => {
      addObject(
        sdk,
        block.attributes.position,
        block.attributes.block_url,
        block.attributes.scale,
        block.attributes.rotation
      );
    });
  }, [sdk, blocks]);

  async function addLight(sdk: any) {
    if (!sdk) return;
    const [sceneObject] = await sdk.Scene.createObjects(1);
    const lightsNode = sceneObject.addNode();
    lightsNode.addComponent("mp.directionalLight", {
      color: { r: 0.7, g: 0.7, b: 0.7 },
    });
    lightsNode.addComponent("mp.ambientLight", {
      intensity: 0.5,
      color: { r: 1.0, g: 1.0, b: 1.0 },
    });
    sceneObject.start();
  }

  async function addObject(sdk, position, url, scale, rotation) {
    if (!sdk) return;
    const [sceneObject] = await sdk.Scene.createObjects(1);
    const modelNode = sceneObject.addNode();
    modelNode.obj3D.position.set(position.x, position.y, position.z);
    modelNode.obj3D.rotation.set(rotation.x, rotation.y, rotation.z);
    const gltfComponent = modelNode.addComponent("mp.gltfLoader", {
      // url: "car.glb",
      url: url,
      localScale: {
        x: scale.x,
        y: scale.y,
        z: scale.z,
      },
    });
    sceneObject.addInputPath(gltfComponent, "url", "gltfUrl");

    const node = sceneObject.addNode();
    node.start();

    sceneObject.start();
  }

  const getWindowSizes = () => {
    const width = window.innerWidth;
    return { width };
  };

  const registerTags = () => {
    iconTags.forEach((icon) => {
      sdk.Mattertag.registerIcon(icon.attributes.name, icon.attributes.url);
    });
  };

  const setImageTag = async (sid) => {
    const tags = propsTags.data;
    const findTag = tags.find((tag) => tag.attributes.sid === sid);
    const iconTag = findTag.attributes.IconTag;
    const findIcon = iconTags.find((icon) => icon.id === iconTag);
    if (!findIcon) return;
    await sdk.Tag.editIcon(sid, findIcon.attributes.name);
  };

  const removeTags = () => {
    sdk.Mattertag.getData()
      .then((tags) => {
        return tags.map((tag) => tag.sid);
      })
      .then((tagSids) => {
        return sdk.Mattertag.remove(tagSids);
      });
  };

  async function addTagsBack() {
    const tags = propsTags.data;
    if (tags && sdk) {
      const tagsSid = tags.map((tag) => {
        const attributes = tag.attributes;
        let tagSid = null;
        // eslint-disable-next-line array-callback-return
        if (!attributes.activate) return;
        return sdk.Mattertag.add([
          {
            label: attributes.label,
            description: attributes.description,
            sid: attributes.id,
            anchorPosition: attributes.Coordinates.anchorPosition,
            stemHeight: 0.15,
            stemVector: attributes.Coordinates.stemPosition,
          },
        ]).then((sid) => {
          tagSid = sid[0];
          const id = tag.id;
          tag.attributes.sid = tagSid;
          tag = tag.attributes;
          tag.id = id;
          setImageTag(tagSid);
          sdk.Tag.allowAction(tagSid, {
            navigating: true,
          });

          return tag;
        });
      });
      Promise.all(tagsSid);
    }
  }

  const handleClickBuy = () => {
    const findTag = propsTags.data.find((ta) => ta.id === tag);
    clickBuy(
      getWindowSizes(),
      findTag.attributes.description,
      findTag.attributes.name
    );
  };

  const handleClickCheckout = async (items) => {
    await goToCheckout(getWindowSizes(), items);
  };

  const handleClickClose = () => {
    const findTag = propsTags.data.find((ta) => ta.id === tag);
    closeModal(
      getWindowSizes(),
      findTag.attributes.description,
      findTag.attributes.label,
      findTag.attributes.modalType
    );
  };

  return (
    <div className={classes.container}>
      {showTransformButtons && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            pointerEvents: "all",
          }}
        >
          <div>
            <button onClick={() => (defaultRotation.x += 0.01)}>
              Rotation X +
            </button>
            <button onClick={() => (defaultRotation.x -= 0.01)}>
              Rotation X -
            </button>
          </div>
          <div>
            <button onClick={() => (defaultRotation.y += 0.01)}>
              Rotation Y +
            </button>
            <button onClick={() => (defaultRotation.y -= 0.01)}>
              Rotation Y -
            </button>
          </div>
          <div>
            <button onClick={() => (defaultRotation.z += 0.01)}>
              Rotation Z +
            </button>
            <button onClick={() => (defaultRotation.z -= 0.01)}>
              Rotation Z -
            </button>
          </div>
        </div>
      )}
      <iframe
        src={`/bundle/showcase.html?m=${idTour}&applicationKey=${sdkKey}&help=0&play=1&qs=1&brand=0&dh=0&gt=0&hr=0&title=0&vr=0&hr=0&mls=2&search=0&tagNav=0 `}
        id="showcase"
        title="tour"
        className="showcase"
        allow=""
      ></iframe>
      <div className={classes.content}>
        <CartContextProvider>
          <NavigationContextProvider>
            {/* <Navbar
              handleClickCheckout={handleClickCheckout}
              visual={visual}
              urlCheckout={urlCheckout}
            /> */}
            <ProductModal
              tagAll={tagAll}
              fromCollection={fromCollection}
              setFromCollection={setFromCollection}
              visual={visual}
              integrationURL={integrationURL}
              opened={isProduct}
              onClosed={(fromCollectionOverride?: boolean) => {
                handleClickClose();
                setFromCollection((fromCollection) => {
                  if (fromCollectionOverride ?? fromCollection) {
                    setIsCollection(true);
                  } else {
                    setIsCollection(false);
                  }
                  setIsGuide(false);
                  return false;
                });
                setIsProduct(false);
                setProduct("");
              }}
              product={product}
              handleClickBuy={handleClickBuy}
            />
            <FloorNav
              sdk={sdk}
              currentFloor={currentFloor}
              setCurrentFloor={(floor) => setCurrentFloor(floor)}
            />
            <InfoModal opened={isGuide} onClosed={() => setIsGuide(false)} />
          </NavigationContextProvider>
        </CartContextProvider>
        <div className={classes.bottom}>
          <button
            className={classes.threeblocks}
            onClick={(event) => {
              window.open("https://3blocks.com.br", "3blocks");
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <img src="/img/3blocks-white.svg" alt="Powered by 3blocks" />
          </button>
          <button
            className={classes.info}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setIsGuide(true);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>COMO</span>
              <span>USAR?</span>
            </div>
          </button>
          {/* <Share /> */}
        </div>
      </div>
    </div>
  );
};
