// @ts-nocheck
import React, { MouseEventHandler, useEffect, useState } from "react";

import { Button, Icon, ModalBody } from "@chakra-ui/react";
import {
  FaArrowRight,
  FaChevronLeft,
  FaChevronRight,
  FaTimes,
} from "react-icons/fa";

import {
  Productmodal,
  VoucherModal,
} from "@3blocks-components/3blocks-components";

import { useCartContext } from "app/contexts/CartContext";
import { classNames } from "app/util/class-names";

import { ModalComponent } from "../components/ModalComponent";

import Voucher1Deskttop from "../../../assets/Voucher1/Voucher_BF2023Voucher_Desk_552x552.png";
import Voucher1Mobile from "../../../assets/Voucher1/Voucher_BF2023Voucher_Mob_328x328.png";

import Voucher2Deskttop from "../../../assets/Voucher2/Voucher_BF2023Voucher_Desk_552x552.png";
import Voucher2Mobile from "../../../assets/Voucher2/Voucher_BF2023Voucher_Mob_328x328.png";

import Voucher3Deskttop from "../../../assets/Voucher3/Voucher_BF2023Voucher_Desk_552x552.png";
import Voucher3Mobile from "../../../assets/Voucher3/Voucher_BF2023Voucher_Mob_328x328.png";

import Voucher4Deskttop from "../../../assets/Voucher4/Voucher_BF2023Voucher_Desk_552x552.png";
import Voucher4Mobile from "../../../assets/Voucher4/Voucher_BF2023Voucher_Mob_328x328.png";

import Voucher5Deskttop from "../../../assets/Voucher5/Voucher_BF2023Voucher_Desk_552x552.png";
import Voucher5Mobile from "../../../assets/Voucher5/Voucher_BF2023Voucher_Mob_328x328.png";

import Voucher6Deskttop from "../../../assets/Voucher6/Voucher_BF2023Voucher_Desk_552x552.png";
import Voucher6Mobile from "../../../assets/Voucher6/Voucher_BF2023Voucher_Mob_328x328.png";

import Voucher7Deskttop from "../../../assets/Voucher7/Voucher_BF2023Voucher_Desk_552x552.png";
import Voucher7Mobile from "../../../assets/Voucher7/Voucher_BF2023Voucher_Mob_328x328.png";

import Voucher8Deskttop from "../../../assets/Voucher8/Voucher_BF2023Voucher_Desk_552x552.png";
import Voucher8Mobile from "../../../assets/Voucher8/Voucher_BF2023Voucher_Mob_328x328.png";

import Voucher9Deskttop from "../../../assets/Voucher9/Voucher_BF2023Voucher_Desk_552x552.png";
import Voucher9Mobile from "../../../assets/Voucher9/Voucher_BF2023Voucher_Mob_328x328.png";

import Voucher10Deskttop from "../../../assets/Voucher10/Voucher_BF2023Voucher_Desk_552x552.png";
import Voucher10Mobile from "../../../assets/Voucher10/Voucher_BF2023Voucher_Mob_328x328.png";

import classes from "./ProductModal.module.scss";
import { Modals3Blocks } from "./modals-3blocks";

const dictionary = {
  "joaninha-design": {
    desktop: Voucher1Deskttop,
    mobile: Voucher1Mobile,
  },
  "DEQ22WES91698-66980": {
    desktop: Voucher2Deskttop,
    mobile: Voucher2Mobile,
  },
  "DEQ23NOV16749-86392": {
    desktop: Voucher3Deskttop,
    mobile: Voucher3Mobile,
  },
  "DEQ21KIT30924-36149": {
    desktop: Voucher4Deskttop,
    mobile: Voucher4Mobile,
  },
  "DEQ21WES89711-46775": {
    desktop: Voucher5Deskttop,
    mobile: Voucher5Mobile,
  },
  "DEQ22VER66999-68995": {
    desktop: Voucher6Deskttop,
    mobile: Voucher6Mobile,
  },
  "DEQ21ADM08985-38125": {
    desktop: Voucher7Deskttop,
    mobile: Voucher7Mobile,
  },
  "DEQ23CEN85746-78382": {
    desktop: Voucher8Deskttop,
    mobile: Voucher8Mobile,
  },
  "DEQ21SPO00872-54463": {
    desktop: Voucher9Deskttop,
    mobile: Voucher9Mobile,
  },
  "DEQ22MGS34541-61608": {
    desktop: Voucher10Deskttop,
    mobile: Voucher10Mobile,
  },
};

const idTour = process.env.REACT_APP_ID_TOUR;
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  banner?: boolean;
  fromCollection?: boolean;
  setFromCollection?: (_: (_: boolean) => boolean) => void;
  opened?: boolean;
  onClosed?: (product?: any) => void;
  product?: any;
  handleClickBuy?: () => void;
  visual?: any;
  integrationURL: string;
  tagAll?: any;
}

export const ProductModal: React.FC<Props> = ({
  handleClickBuy,
  banner,
  fromCollection,
  onClosed,
  product: idProduct,
  setFromCollection,
  visual,
  opened,
  tagAll,
  integrationURL,
  ...props
}) => {
  const { setCartItems } = useCartContext();
  const [product, setProduct] = useState<any>();
  const [productItem, setProductItem] = useState<any>();
  const [productItemImage, setProductItemImage] = useState<any>();
  const [productSimilars, setProductSimilars] = useState<any[]>([]);
  const [showDescription, setShowDescription] = useState(false);
  const [renderComplete, setRenderComplete] = useState(false);
  const [showVoucher, setShowVoucher] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const title = document.querySelector(".productcard__title");
      if (title || idProduct === "joaninha-design") {
        setShowVoucher(true);
        setRenderComplete(true);
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [product]);

  const [error, setError] = useState(false);
  const handleBack = () => {
    setProduct(undefined);
    setShowDescription(false);
    onClosed();
  };
  const handleClose = () => {
    setError(false);
    setProduct(undefined);
    setShowDescription(false);
    onClosed(false);
    setShowVoucher(false);
  };
  const handleBuy = (count) => {
    handleClickBuy && handleClickBuy();
    const productLink = product?.link || "https://www.westwing.com.br/";
    window.open(productLink, "_blank");
    // window.parent.location.href = productLink;
    return;
    setCartItems((cartItems) => {
      const items = [...cartItems];
      const item = items.find((item) => item.id === productItem.id);
      if (item) {
        items.splice(items.indexOf(item), 1, {
          ...item,
          quantity: item.quantity + count,
        });
      } else {
        items.push({
          id: productItem.id,
          image: productItem.images[0].url,
          name: productItem.nameComplete,
          price: productItem.offer.price,
          quantity: count,
          seller: productItem.offer.seller,
        });
      }
      return items;
    });
    handleClose();
  };

  const handleSimilar = (id) => {
    fetch(`${apiUrl}${idTour}/${id}`)
      .then((response) => response.json())
      .then((response) => setProduct(response));
  };
  useEffect(() => {
    if (idProduct) {
      fetch(`${integrationURL}/${idProduct}`)
        .then((response) => {
          if (response.status === 500) {
            setError(true);
          }
          return response.json();
        })
        .then((response) => setProduct(response))
        .catch((err) => {});
    }
  }, [idProduct]);
  useEffect(() => {
    // if (product?.id) {
    //   fetch(`${integrationURL}/${product.id}/similars`)
    //     .then((response) => response.json())
    //     .then((response) => {
    //       const similars = [...response, product];
    //       setProductSimilars(
    //         similars.sort((product, { id }) => id - product.id)
    //       );
    //     });
    // } else {
    setProductSimilars([]);
    // }
  }, [product]);
  useEffect(() => setProductItem(product?.items?.[0]), [product]);
  useEffect(() => setProductItemImage(productItem?.images?.[0]), [productItem]);

  const objectVisual = {
    visualType: visual.attributes.visualType,
    bgButton3DColor: visual.attributes.bgButton3DColor,
    Button3DColor: visual.attributes.Button3DColor,
    primaryFontColor: visual.attributes.primaryFontColor,
    secondaryFontColor: visual.attributes.secondaryFontColor,
  };

  const renderVoucher = () => {
    const hasVoucher = dictionary[idProduct];

    if (!hasVoucher) return null;
    return (
      <VoucherModal
        imageDesktop={hasVoucher?.desktop}
        imageMobile={hasVoucher.mobile}
        link={
          idProduct === "joaninha-design"
            ? "https://now.westwing.com.br/projetos-de-decoracao-westwing-design/"
            : "https://now.westwing.com.br/landing/tour-virtual-loja"
        }
        headless={true}
        isOpen={showVoucher}
        title="Ganhe R$ 50,00 de desconto"
        onClose={() => {
          if (idProduct === "joaninha-design") {
            setShowVoucher(false);
            handleClose();
          } else {
            setShowVoucher(false);
          }
        }}
      />
    );
  };

  if (!opened) return;

  return (
    <div>
      {!tagAll?.attributes.type_v2 ? (
        <div>
          <Productmodal
            modal={true}
            open={true}
            similar={null}
            handleClickSimilar={handleSimilar}
            handleBuy={handleBuy}
            handleClose={handleClose}
            product={product}
            onlyDevMockType="default"
            visual={objectVisual}
            textButtonBuy="Ver mais sobre o produto"
            buttonSeeMoreSpecification={false}
            alwaysAvailable={true}
            priceFontSize={1.5}
            showCountPrice={false}
          />
        </div>
      ) : (
        <div>
          <Modals3Blocks
            objectVisual={objectVisual}
            tag={tagAll.attributes}
            handleBuy={handleBuy}
            handleClose={handleClose}
            onClose={handleClose}
            urlProduct={integrationURL}
          />
        </div>
      )}
      {/* {renderComplete && renderVoucher()} */}
    </div>
  );
};
