import React, { useEffect } from "react";

//@ts-ignore
import classes from "./FloorNav.module.scss";

export const FloorNav = ({ sdk, currentFloor = 0, setCurrentFloor }: any) => {
  // sdk.Floor.getData().then((floors) => {
  //   console.log("floors", floors);
  //   sdk.Floor.moveTo(2);
  // });
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <p
          style={{
            color: currentFloor === 2 ? "white" : "black",
            backgroundColor: currentFloor === 2 ? "#00BAAC" : "white",
            borderRadius: "0 8px 0 0",
          }}
          onClick={() => {
            sdk.Floor.moveTo(2).then(() => {
              setCurrentFloor(2);
            });
          }}
        >
          Andar 2
        </p>
        <p
          style={{
            color:
              currentFloor === 0 || currentFloor === -1 ? "white" : "black",
            backgroundColor:
              currentFloor === 0 || currentFloor === -1 ? "#00BAAC" : "white",
            borderRadius: "0 0 8px 0",
          }}
          onClick={() => {
            sdk.Floor.moveTo(0).then(() => {
              setCurrentFloor(0);
            });
          }}
        >
          Andar 1
        </p>
      </div>
    </div>
  );
};
