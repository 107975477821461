const apiUrl = process.env.REACT_APP_ADMIN_URL;
const tourId = process.env.REACT_APP_ID_TOUR;

const token =
  "91c3352d6a23ce74fdceb0f8b27e66bb9e958088420a4894f2fda813bd7eecfd5c719bd9e3943df796035d13c5027b18adcde3f649d5f1cf2331ea4f57351ab87c3df70841b538a2c47888599acde1a22a66a0be851e99abe4214a9ef4d98801986d0f45642379273a9e5104202d6ad3e17d9c00401e48303c29282323327675";

export const loadIntegration = async () => {
  const response = await fetch(
    apiUrl + "integrations?filters%5Btours%5D[id]=" + tourId,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      referrerPolicy: "no-referrer",
    }
  );
  return response.json();
};

export const loadTags = async () => {
  const response = await fetch(
    apiUrl +
      "tags?populate=*&pagination[pageSize]=200&filters%5Btour%5D[id]=" +
      tourId,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      referrerPolicy: "no-referrer",
    }
  );
  return response.json();
};

export const loadBlocks = async () => {
  const response = await fetch(
    apiUrl + "blocks?populate=*&filters%5Btour%5D[id]=" + tourId,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      referrerPolicy: "no-referrer",
    }
  );
  return response.json();
};

export const loadObjects = async () => {
  const response = await fetch(apiUrl + "objects?populate=*", {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    referrerPolicy: "no-referrer",
  });
  return response.json();
};

export const loadVisuals = async () => {
  const response = await fetch(
    apiUrl + "visuals?populate=*&filters%5Btour%5D[id]=" + tourId,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      referrerPolicy: "no-referrer",
    }
  );
  return response.json();
};
