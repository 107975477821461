import React from "react";
import { Container } from "./ui/Container";
import useSWR from "swr";
import {
  loadBlocks,
  loadIntegration,
  loadObjects,
  loadTags,
  loadVisuals,
} from "./services";

export const ServiceComponent: React.FC = () => {
  const { data: integration } = useSWR("integration", loadIntegration);
  const { data: tags } = useSWR("tags", loadTags);
  const { data: visual } = useSWR("visual", loadVisuals);
  const { data: blocks } = useSWR("blocks", loadBlocks);
  // const { data: objects } = useSWR("objects", loadObjects);

  if (!integration || !tags || !visual) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Container
        propsTags={tags}
        integration={integration}
        visual={visual.data[0]}
        blocks={blocks.data}
        // objects={objects.data}
      />
    </>
  );
};

export default ServiceComponent;
